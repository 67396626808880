<template>
  <div>
    <section class="section-box section-box--black section-box--first">
      <div class="wrapper">
        <v-main-info :data="mainInfoData" class="main-info--no-visual"></v-main-info>
      </div>
    </section>
    <section class="section-box section-box--highlighted">
      <div class="wrapper">
        <transition-group name="small-slide-up">
          <pulse-loader key="loader" v-if="!careersListLoaded" :color="loaderOptions.color" :size="loaderOptions.size"></pulse-loader>
          <v-careers-list
              v-if="careersListLoaded"
              key="list"
              :list="careersList"
              :filter-department="currentDepartmentTab"
              :filter-location="currentLocationTab"
              :data-loaded="careersListLoaded"
          />
        </transition-group>
      </div>
    </section>
  </div>
</template>

<script>
//Components
import MainInfo from "@/components/layout/Main-info/Main-info";
import CareersList from '@/components/layout/Careers/Careers-list'
//Helpers
import * as api from "@/helpers/Api";
//Plugins
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
import { eventBus } from '@/main'

export default {
  name: "Careers",
  metaInfo: {
    title: 'Careers – ITernal group'
  },
  data() {
    return {
      mainInfoData: {
        title: 'Careers',
        subTitle: 'We\'re looking for bright minds and tech talents who want to impact the world. Discover how you can take your career further and redefine your future with ITernal.'
      },
      careersList: [],
      careersListAll: [],
      careersItem: {},
      careersListLoaded: false,
      filters: {},
      currentDepartmentTab: 'All',
      currentLocationTab: 'All',
      loaderOptions: {
        size: '15px',
        color: '#FF3200'
      }
    }
  },
  components: {
    'v-main-info': MainInfo,
    'v-careers-list': CareersList,
    PulseLoader
  },
  methods: {
    async getData() {
      await api.getVacancies().then(result => {
        if (result) {
          this.careersList = result.data;
          this.careersListAll = result.data;
          this.careersListLoaded = true;
        }
      })
    },
    isArrayNotEmpty(array) {
      return (array && Array.isArray(array) && array.length)
    },
    byDepartment(item, value) {
      return value ? item.department.name === value : false
    },
    byLocation(item, value) {
      return item.location && item.location.match(value)
    },
    filterByAll({department, location}) {
      if (department) {
        this.filters.department = department
      }
      if (location) {
        this.filters.location = location
      }
      this.careersList = this.careersListAll.filter(
        item => {
          const matchedByDepartment = (this.filters.department && this.filters.department !== 'All') ? this.byDepartment(item, this.filters.department) : true;
          const matchedByLocation = (this.filters.location && this.filters.location !== 'All') ? this.byLocation(item, this.filters.location) : true;
          return matchedByDepartment && matchedByLocation
        }
      )
    }
  },
  created() {
    this.getData();
  },
  mounted() {
    eventBus.$on('filteringByDepartment', value => {
      this.currentDepartmentTab = value
      this.filterByAll({department: value})
    })
    eventBus.$on('filteringByLocation', value => {
      this.currentLocationTab = value
      this.filterByAll({location: value})
    })
  }
}
</script>

<style lang="scss">
  
</style>
